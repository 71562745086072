import(/* webpackMode: "eager" */ "/app/apps/arw/src/components/global/muiTheme/MuiTheme.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/arw/src/style/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material-nextjs@5.15.11_@mui+material@5.15.16_@types+react@18.3.1_next@14.2.3_react@18.3.1/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/RapidST_Bold.woff2\",\"display\":\"swap\",\"variable\":\"--f-serif\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"rapidBold\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--f-sans\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/lib/src/services/i18n/client.ts");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-web/src/components/cssVariables/CssVariables.tsx");
